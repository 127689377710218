/**
 * SNSログイン用middleware
 */
import { COGNITO_PROVIDER_IDS } from '@/features/user/constants';
import { useCognitoAuth } from '@/composables/useCognitoAuth';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!to.query.providerId) return;

  const {
    handleLineIntegration,
    handleAppleIntegration,
    handleGoogleIntegration,
  } = useCognitoAuth();
  const { setSnsRedirectPathToApp } = useFlutterConnection();

  await setSnsRedirectPathToApp('/mypage/integration/');
  const providerId = Number(to.query.providerId);

  if (providerId === COGNITO_PROVIDER_IDS.LINE) {
    await handleLineIntegration();
  }
  if (providerId === COGNITO_PROVIDER_IDS.APPLE) {
    await handleAppleIntegration();
  }
  if (providerId === COGNITO_PROVIDER_IDS.GOOGLE) {
    await handleGoogleIntegration();
  }
});
